/* Work page header */
.work-header-wrapper {
	background: $white;
	padding: rem-calc(16 0);

	@include breakpoint(large) {
		padding: rem-calc(22 0);
	}
}

.work-header-title {
	font-size: rem-calc(26);
	text-transform: uppercase;
	margin-bottom: 0;

	@include breakpoint(large) {
		font-size: rem-calc(36);
	}
}

.work-page-hero-image {
	height: rem-calc(380);
	background-repeat: no-repeat; 
	background-size: auto 100%; 
	background-position: center;

	@include breakpoint(medium) {
		height: rem-calc(424);
	}

	@include breakpoint(large) {
		height: rem-calc(520);
	}
}

/* Work page info panel */
.work-info-panel-wrapper {
	background: $white;
	padding: rem-calc(32 0 27 0);
}

.work-info-panel-label {
	font-size: rem-calc(14);
	color: #555;
	display: block;
	margin-bottom: rem-calc(8);
}

.general-info-text {
	font-size: rem-calc(14);
	font-weight: $global-weight-bold;
	color: #555;
	margin-bottom: 0;

	@include breakpoint(medium) {
		margin-bottom: rem-calc(29);
	}
}

.work-project-general-info {
	border-top: 1px solid #D6D6D6;
	padding-top: rem-calc(16);

	.work-info-panel-label {
		margin-bottom: rem-calc(4);
	}

	@include breakpoint(small down) {
		.grid-container {
			padding-left: 0;
			padding-right: 0;
		}

		.work-project-client {
			padding-right: rem-calc(16);
		}
	}

	@include breakpoint(medium) {
		border-top: none;
		border-left: 1px solid #D6D6D6;
		padding-top: 0;
	}
}

.work-images-content {
	@include breakpoint(large down) {
		padding: 0;
	}

	img {
		width: 100%;
		margin-bottom: rem-calc(8);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(16);
		}

		@include breakpoint(large) {
			margin-bottom: rem-calc(32);
		}
	}
}