.homepage-header-wrapper {
	background: $brand-primary-color;
	height: calc(100vh - 160px);
	min-height: rem-calc(300);
	max-height: rem-calc(800);
	color: $white;
	padding-bottom: rem-calc(80);
	position: relative;
}

.homepage-logo {
	margin-top: rem-calc(24);
	margin-bottom: rem-calc(36);
	height: rem-calc(116);

	@include breakpoint(medium) {
		margin-top: rem-calc(32);
		margin-bottom: rem-calc(48);
	}
}

.homepage-header-pagetitle {
	text-transform: uppercase;

	@include breakpoint(medium) {
		margin-bottom: rem-calc(24);
	}
}

.hompage-scroll-down-arrow {
	width: rem-calc(32);
	height: rem-calc(32);
	font-size: rem-calc(32);
	position: absolute;
	bottom: rem-calc(32);
	left: 50%;
	transform: translateX(-16px);
}