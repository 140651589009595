.portfolio-list-section-wrapper {
	margin-top: rem-calc(28);
	margin-bottom: rem-calc(26);

	@include breakpoint(medium) {
		margin-top: rem-calc(36);
		margin-bottom: rem-calc(32);
	}

	@include breakpoint(large) {
		margin-top: rem-calc(56);
		margin-bottom: rem-calc(48);
	}

	@include breakpoint(small only) {
		.grid-container {
			max-width: rem-calc(420);
		}
	}

	@include breakpoint(large only) {
		.portfolio-list-section-content {
			max-width: rem-calc(860);
		}
	}
}

/* Portfolio item */
.grid-sizer,
.grid-item {
	float: left;
	width: 100%;
	height: rem-calc(432);

	@include breakpoint(medium) {
		width: calc(50% - 8px);
	}

	@include breakpoint(large) {
		width: calc(50% - 16px);
	}

	@include breakpoint(xlarge) {
		width: calc(33.33% - 21px);
	}
}

.gutter-sizer {
	width: rem-calc(16);

	@include breakpoint(large) {
		width: rem-calc(32);
	}
}

.portfolio-list-item {
	position: relative;
	border-radius: $brand-border-radius * 2;
	background-color: $brand-light-gray;
	background-size: auto 100%;
	background-position: center;
	background-repeat: no-repeat;

	&::before {
		content: " ";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0,0,0,0);
		border-radius: $brand-border-radius * 2;
		transition: background 0.25s ease-out;
	}

	&:hover {
		&::before {
			background: rgba(0,0,0,0.25);
		}
	}
}

.portfolio-list-wide-item {
	width: 100%;

	@include breakpoint(medium) {
		width: 100%;

		.portfolio-list-item-info {
			width: 50%;
			max-width: rem-calc(383);
			right: 50%;
		}
	}

	@include breakpoint(xlarge) {
		width: calc(66.66% - 10px);
	}
}

.portfolio-list-item-info {
	color: $black;
	position: absolute;
	left: rem-calc(8);
	right: rem-calc(8);
	bottom: rem-calc(16);
	padding: rem-calc(12 0 12 12);
	background: $white;
	border-radius: $brand-border-radius;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.4);

	@include breakpoint(medium) {
		left: rem-calc(16);
		right: rem-calc(16);
	}
}

.portfolio-list-item-title {
	font-size: rem-calc(20);
	margin-bottom: 0;
}

.portfolio-list-item-client {
	font-size: rem-calc(14);
	margin-bottom: 0;
}

.portfolio-list-item-arrow {
	font-size: rem-calc(40);
	color: $brand-primary-color;
	padding-left: rem-calc(8);
	padding-right: rem-calc(8);
	margin-left: rem-calc(8);
	border-left: 1px solid $brand-primary-color;
	display: flex;
	align-items: center;

	.icon {
		width: rem-calc(40);
		height: rem-calc(40);
	}
}

/* Portfolio page header */
.portfolio-page-header {
	width: 100%;
	height: auto;
	padding-bottom: rem-calc(40);

	@include breakpoint(medium) {
		width: 100%;
		padding-left: 15%;
		padding-right: 15%;
		padding-bottom: rem-calc(40);
		text-align: center;
	}

	@include breakpoint(xlarge) {
		width: calc(33.33% - 21px);
		height: rem-calc(432);
		padding: 0;
		text-align: left;
	}
}

.portfolio-header-pagetitle {
	text-transform: uppercase;
}

.portfolio-header-description {
	margin-bottom: 0;
}