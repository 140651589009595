$icomoon-font-path: "../fonts" !default;

$icon-key-arrow-right: "\e90d";
$icon-key-arrow-down: "\e90c";
$icon-arrow-prev: "\e90a";
$icon-arrow-next: "\e90b";
$icon-email: "\e908";
$icon-phone: "\e909";
$icon-close: "\e906";
$icon-menu: "\e907";
$icon-woao-logo: "\e905";
$icon-behance: "\e900";
$icon-facebook: "\e901";
$icon-instagram: "\e902";
$icon-linkedin: "\e903";
$icon-twitter: "\e904";

