@import "variables";

@font-face {
  font-family: 'woao-icons';
  src:  url('#{$icomoon-font-path}/woao-icons.eot?3appmy');
  src:  url('#{$icomoon-font-path}/woao-icons.eot?3appmy#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/woao-icons.ttf?3appmy') format('truetype'),
    url('#{$icomoon-font-path}/woao-icons.woff?3appmy') format('woff'),
    url('#{$icomoon-font-path}/woao-icons.svg?3appmy#woao-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'woao-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-key-arrow-right {
  &:before {
    content: $icon-key-arrow-right;
  }
}
.icon-key-arrow-down {
  &:before {
    content: $icon-key-arrow-down;
  }
}
.icon-arrow-prev {
  &:before {
    content: $icon-arrow-prev;
  }
}
.icon-arrow-next {
  &:before {
    content: $icon-arrow-next;
  }
}
.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-woao-logo {
  &:before {
    content: $icon-woao-logo;
  }
}
.icon-behance {
  &:before {
    content: $icon-behance;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}

