/* Global work slider and wrapper settings */
.work-carousel-section-wrapper {
	background: $brand-light-gray;
	padding: rem-calc(29 0 24 0);

	@include breakpoint(medium) {
		padding: rem-calc(38 0 32 0);
	}
}

.works-carousel-section-title {
	font-size: rem-calc(20);
	text-transform: uppercase;
	margin-bottom: rem-calc(27);

	@include breakpoint(medium) {
		margin-bottom: rem-calc(36);
	}
}

.works-carousel {
	max-width: rem-calc(1262);

	@include breakpoint(400) {
		padding-left: calc((100% - 384px) / 2);
		padding-right: calc((100% - 384px) / 2);
	}

	@include breakpoint(medium) {
		padding-left: rem-calc(32);
		padding-right: rem-calc(32);
	}

	@include breakpoint(large) {
		padding-left: calc((100% - 798px) / 2);
		padding-right: calc((100% - 798px) / 2);
	}

	@include breakpoint(1100) {
		padding-left: rem-calc(32);
		padding-right: rem-calc(32);

		.swiper-navigation {
			display: none;
		}
	}
}

.works-carousel-slide.swiper-slide {
	.grid-item {
		width: 100% !important;

		.portfolio-list-item-info {
			right: rem-calc(8);

			@include breakpoint(medium) {
				width: auto;
				max-width: 100%;
				left: rem-calc(16);
				right: rem-calc(16);
			}
		}
	}	
}

/* Recent works settings */
.recent-works-section-wrapper {
	.button {
		margin-top: rem-calc(24);
		margin-bottom: 0;

		@include breakpoint(medium) {
			margin-top: rem-calc(32);
		}
	}
}