.testimonials-section-wrapper {
	background: $brand-light-gray;
	padding: rem-calc(29 0 24 0);

	@include breakpoint(medium) {
		padding: rem-calc(38 0 32 0);
	}
}

.testimonials-section-title {
	font-size: rem-calc(20);
	text-transform: uppercase;
	margin-bottom: rem-calc(27);

	@include breakpoint(medium) {
		margin-bottom: rem-calc(36);
	}
}

.testimonial-item {
	width: calc(100% - 80px) !important;
	max-width: rem-calc(720);
	background: $white;
	border-radius: $brand-border-radius;
	box-shadow: 0 2px 4px 0px rgba(0,0,0,0.3);
	opacity: 0.4;
	transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out;

	@include breakpoint(370 down) {
		width: calc(100% - 52px) !important;
	}

	@include breakpoint(medium) {
		width: calc(100% - 112px) !important;
	}

	&.swiper-slide-active {
		opacity: 1;
		box-shadow: 0 2px 16px 0px rgba(0,0,0,0.3);
	}

	blockquote {
		border: none;
		padding: 0;
		margin: 0;
	}
}

.testimonial-cite {
	font-size: rem-calc(14);
	line-height: 1.35;
	font-weight: $global-weight-semi-bold;
	font-style: normal;
	color: $white;
	position: relative;
	background: $brand-primary-color;
	border-top-left-radius: $brand-border-radius;
	border-top-right-radius: $brand-border-radius;

	&:before {
		position: absolute;
		bottom: rem-calc(-24);
		left: rem-calc(22);
		width: rem-calc(48);
		height: rem-calc(48);
		background-color: $white;
		background-image: url('../img/quote.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 32px;
		border-radius: 100%;
		content: " ";
	}

	.testimonial-cite-content {
		padding: rem-calc(16 16 34 16);
		//display: inline-block;
	}

	@include breakpoint(medium) {
		width: rem-calc(226);
		border-top-right-radius: 0;
		border-bottom-left-radius: $brand-border-radius;

		&:before {
			top: rem-calc(40);
			left: rem-calc(202);
			bottom: auto;
		}

		.testimonial-cite-content {
			padding: rem-calc(34 54 34 34);
		}
	}
}

.testimonial-image {
	width: rem-calc(56);
	height: rem-calc(56);
	margin-right: rem-calc(16);
	border: 3px solid $white;
	border-radius: 100%;
	background: $white;
	display: block;
	float: left;

	img {
		border-radius: 100%;
		width: rem-calc(56);
		height: rem-calc(56);
		display: inline-block;
	}

	@include breakpoint(medium) {
		margin-bottom: rem-calc(16);
	}
}

.testimonial-name {
	font-size: rem-calc(21);
	font-weight: $global-weight-bold;

	@include breakpoint(medium) {
		display: inline-block;
		padding-bottom: rem-calc(8);
	}
}

.testimonial-company {
	display: inline-block;
}

.testimonial-text {
	font-size: rem-calc(18);
	font-style: italic;
	color: $black;
	padding: rem-calc(40 16 42 16);
	margin-bottom: 0 !important;

	@include breakpoint(medium) {
		padding: rem-calc(40 40 40 60);
	}
}

.swiper-navigation {
	margin-top: rem-calc(24);
	max-width: rem-calc(720);
	padding: rem-calc(0 40);

	@include breakpoint(370 down) {
		padding: rem-calc(0 26);
	}

	@include breakpoint(medium) {
		margin-top: rem-calc(32);
		padding: 0;
		width: calc(100% - 112px);
	}
}