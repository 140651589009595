.woao-team-section-wrapper {
	padding-top: rem-calc(32);
	padding-bottom: rem-calc(32);

	@include breakpoint(medium) {
		padding-top: rem-calc(40);
		padding-bottom: rem-calc(40);
	}

	@include breakpoint(medium) {
		padding-top: rem-calc(56);
		padding-bottom: rem-calc(56);
	}
}

.woao-team-section-title {
	text-transform: uppercase;
	margin-bottom: rem-calc(26);

	@include breakpoint(medium) {
		margin-bottom: rem-calc(42);
	}

	.icon-woao-logo {
		font-size: rem-calc(23);
		color: $brand-primary-color;
		margin-right: rem-calc(8);
	}
}

.team-member {
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
	border-radius: $brand-border-radius;
	margin-bottom: rem-calc(16);

	&:last-child {
		margin-bottom: 0;
	}

	@include breakpoint(large) {
		margin-bottom: rem-calc(24);
	}

	&.is-active .team-member-header::before {
		content: url('../img/plus-icon.svg');
		transform: rotate(45deg);
	}
}

.team-member-header {
	padding: rem-calc(24 0 24 16);
	border-radius: $brand-border-radius;
	border: none;
	background-color: transparent;
	font-weight: $global-weight-bold;
	line-height: 1.3;
	font-size: rem-calc(16);
	color: $black;

	&::before {
		content: url('../img/plus-icon.svg');
		width: rem-calc(32);
		height: rem-calc(32);
		border-radius: rem-calc(16);
		top: rem-calc(41);
		right: rem-calc(16);
		display: inline-block;
		transform: rotate(0deg);
		transition: all 0.25s ease-out;
		
	}

	&:hover {
		background-color: $brand-light-gray;
	}

	@include breakpoint(370 down) {
		padding: rem-calc(18 0 18 10);
		background-size: rem-calc(100);
		font-size: rem-calc(14);

		&::before {
			right: rem-calc(10);
		}
	}

	@include breakpoint(medium) {
		padding: rem-calc(36 0 36 36);
		line-height: 1.5;
		background-size: rem-calc(162) auto;

		&::before {
			top: rem-calc(58);
			right: rem-calc(36);
		}
	}
}

.team-member-header-content {
	border-bottom: 1px solid $brand-light-gray-2;
	margin-bottom: rem-calc(8);
	margin-left: rem-calc(16);
	padding-right: rem-calc(56);

	@include breakpoint(370 down) {
		margin-left: rem-calc(10);
	}

	@include breakpoint(medium) {
		margin-left: rem-calc(24);
	}
}

.team-member-image {
	border: 4px solid $brand-light-gray-2;
	width: rem-calc(80);
	height: rem-calc(80);

	@include breakpoint(medium) {
		width: rem-calc(96);
		height: rem-calc(96);
	}

	img {
		width: 100%;
		height: 100%;
	}
}

.team-member-image,
.team-member-image img {
	border-radius: 100%;
}

.team-member-name {
	font-size: rem-calc(20);
	color: $brand-primary-color;
	display: inline-block;

	@include breakpoint(370 down) {
		font-size: rem-calc(18);
	}

	@iclude breakpoint(medium) {
		font-size: rem-calc(22);
	}
}

.team-member-position {
	font-size: rem-calc(16);
	color: $black;

	@include breakpoint(370 down) {
		font-size: rem-calc(14);
	}
}

.team-member-content {
	border-bottom-left-radius: $brand-border-radius;
	border-bottom-right-radius: $brand-border-radius;
	border: none;
	padding: rem-calc(8 24 32 24);

	@include breakpoint(370 down) {
		padding: rem-calc(8 16 24 16);
	}

	@include breakpoint(medium) {
		padding: rem-calc(8 32 48 157);
	}

	.social-links {
		margin-bottom: 0;
	}
}