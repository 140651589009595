.footer-wrapper {
	background-color: $white;
	padding-top: rem-calc(16);
}

.footer-content {
	padding: rem-calc(24 0);

	@include breakpoint(medium) {
		padding-bottom: rem-calc(32);
	}
}

.copyright {
	font-size: rem-calc(13);
	color: $brand-dark-gray;
}