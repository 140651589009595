/* Contact info */
.contact-phone,
.contact-email {
	font-size: rem-calc(20);
	font-weight: $global-weight-semi-bold;

	p {
		margin-bottom: rem-calc(24);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(40);
			margin-left: rem-calc(20);
			margin-right: rem-calc(20);
		}
	}

	.icon {
		font-size: rem-calc(32);
		margin-right: rem-calc(8);
		margin-top: rem-calc(-3);
		display: inline-block;
	}
}

/* Contact form */
.contact-form-section {
	margin-top: rem-calc(64);
	margin-bottom: rem-calc(34);

	@include breakpoint(medium) {
		margin-top: rem-calc(77);
		margin-bottom: rem-calc(50);
	}
}

.contact-section-title {
	text-transform: uppercase;
	margin-bottom: rem-calc(24);

	@include breakpoint(medium) {
		margin-bottom: rem-calc(40);
	}
}