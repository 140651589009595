.page-header-content {
	margin-top: rem-calc(28);
	margin-bottom: rem-calc(40);

	@include breakpoint(medium) {
		margin-top: rem-calc(36);
		margin-bottom: rem-calc(48);
	}

	@include breakpoint(large) {
		margin-top: rem-calc(56);
		margin-bottom: rem-calc(56);
	}
}

.page-header-pagetitle {
	text-transform: uppercase;
	margin-bottom: rem-calc(8);
}

.page-header-description {
	margin-bottom: 0;
}