/* Links animation */
a,
button.mobile-menu,
.swiper-button-prev,
.swiper-button-next {
	transition: all 0.25s ease-out;
}

/* WOAO style button */
.woao-button {
	font-size: rem-calc(17);
	font-weight: $global-weight-semi-bold;
	line-height: 1;
	color: $white;
	background: $woao-button-color;
	padding: rem-calc(16 24);
	border-radius: rem-calc(30);

	&:hover,
	&:focus {
		background: $woao-button-hover-color;
		color: #white;
	}
}

/* Icon font icons align middle */
i.icon {
	vertical-align: middle;
}
/* Social links style */
.social-links {
	margin-bottom: rem-calc(24);

	@include breakpoint(medium) {
		margin-bottom: 0;
	}

	a {
		font-size: rem-calc(30);
		color: $black;
		margin-right: rem-calc(8);

		&:hover,
		&:focus {
			&.facebook {
				color: $facebook;
			}

			&.behance {
				color: $behance;
			}

			&.linkedin {
				color: $linkedin;
			}

			&.twitter {
				color: $twitter;
			}

			&.instagram {
				color: $instagram;
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

/* Form inputs modifications */
input[type="email"],
input[type="text"],
textarea {
	border-top: none;
	border-right: none;
	border-left: none;
	padding-left: 0;
	padding-right: 0;

	&:focus {
		border-top: none;
		border-right: none;
		border-left: none;
		padding-left: 0;
		padding-right: 0;
	}

	&::placeholder,
	&::-webkit-input-placeholder {
		font-weight: $global-weight-semi-bold !important;
	}

	&.is-invalid-input {
		background-color: transparent !important;
	}
}

form.contact-form .woao-button {
	margin-top: rem-calc(16);
}