.global-site-header {
	padding: rem-calc(16 0);

	@include breakpoint(medium) {
		padding-top: rem-calc(24);
	}

	.icon {
		font-size: rem-calc(30);
		vertical-align: middle;
		color: $global-nav-color;
		cursor: pointer;

		&:hover,
		&:focus {
			color: $global-nav-hover-color;
		}
	}

	.mobile-menu-close {
		margin: rem-calc(16 0);
		padding-right: rem-calc(16);
	}
}

.site-logo {
	font-size: rem-calc(24);
	line-height: 0.2;
	margin-top: rem-calc(2);
	color: $global-nav-active-color;
	display: flex;
	align-items: center;

	&:hover {
		color: $global-nav-hover-color;
	}
}

.global-nav-menu {
	li {
		a {
			font-size: rem-calc(17);
			font-weight: $global-nav-weight;
			text-transform: uppercase;
			color: $global-nav-color;

			&:hover,
			&:focus {
				color: $global-nav-hover-color;
			}
		}

		@include breakpoint(medium) {
			margin-right: rem-calc(10);
			margin-right: rem-calc(10);

			a {
				padding-left: rem-calc(8);
				padding-right: rem-calc(8);
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	@include breakpoint(small only) {
		li {
			margin: rem-calc(0 0 0 24);
			border-bottom: 1px solid $medium-gray;

			a {
				padding-left: 0;
				padding-right: 0;
				margin: rem-calc(6 0);
			}

			&:last-child {
				border: none;
			}
		}
	}
}